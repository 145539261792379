import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

import './index.css';

const IndexPage = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const yearsOfExperience = currentYear - 2014;

    return (
        <Layout>
            <SEO
                title="Jose Santos - Home"
                keywords={[
                    `jose santos`,
                    `javascript`,
                    `node`,
                    `react`,
                    `performance`,
                    `software engineer`,
                    `senior software engineer`,
                    `frontend`,
                    `java`,
                    `backend`,
                    `c++`,
                    `airbnb`,
                    `google`,
                ]}
            />
            <h1>Hello, and welcome!</h1>
            <p className="main-paragraph">
                I am <strong>Jose Santos</strong>. A Senior Software Engineer
                based in Sunnyvale, CA. Currently, I work for{' '}
                <strong>Airbnb</strong>. Previously I worked at Google, GumGum,
                and a few other places. I have over {yearsOfExperience} years of
                experience building rich web applications using popular
                languages such as JavaScript, Java and C++.
            </p>
            <p>Some things that excite me:</p>
            <ul>
                <li>Progressive Web Apps</li>
                <li>Functional Programming</li>
                <li>Web Peformance</li>
                <li>Machine Learning</li>
            </ul>
            <p>Open Source:</p>
            <ul>
                <li>
                    <a href="https://github.com/gumgum/gumdrops">Gumdrops</a>
                    {' - Library of UI components used at GumGum, Inc.'}
                </li>
                <li>
                    <a href="https://github.com/gumgum/react-script-tag">
                        React Script Tag
                    </a>
                    {
                        ' - Drop in replacement for the native </script> tag in React'
                    }
                </li>
                <li>
                    <a href="https://github.com/JMSantos94/use-react">
                        Use React
                    </a>
                    {
                        ' - Collection of React Hooks that facilitates working with js primitives'
                    }
                </li>
            </ul>
            <p>Blog Posts:</p>
            <ul>
                <li>
                    <a href="https://medium.com/gumgum-tech/serverless-pdf-generation-881fae87a058">
                        Serverless PDF Generation
                    </a>
                </li>
                <li>
                    <a href="https://medium.com/gumgum-tech/performance-matters-part-1-8b9325a3a830">
                        Performance Matters - Part 1
                    </a>
                </li>
            </ul>
            <p style={{ textAlign: 'center' }}>
                <a href="https://twitter.com/JMSantos94">Twitter</a>
                {' - '}
                <a href="https://www.linkedin.com/in/jmsantos94/">LinkedIn</a>
                {' - '}
                <a href="https://github.com/JMSantos94">GitHub</a>
            </p>
        </Layout>
    );
};

export default IndexPage;
